import { configureStore } from '@reduxjs/toolkit';
import auth from './features/auth';
import userProfile from './features/userProfile';
import userLocation from './features/userLocation';
import organisationProfile from './features/organisationProfile';
import boostCallEvents from './features/boostCallEvents';
import partner from './features/partner';
import messages from './features/messages';
import theme from 'features/reduxStore/theme/theme';
import action from 'features/reduxStore/actionPlans/actionPlansSlice';
import attachments from 'features/reduxStore/attachments/attachments';
import taxonomy from 'features/reduxStore/taxonomy/taxonomy';
import messagingCentre from 'apiHooks/messagingCentre';
import sessionTitle from 'features/reduxStore/sessionTitle';
import { createAction } from '@reduxjs/toolkit';

export const RESET_STORE = createAction('RESET_STORE');
export const store = configureStore({
  reducer: {
    auth,
    sessionTitle,
    userProfile,
    userLocation,
    organisationProfile,
    partner,
    boostCallEvents,
    messages,
    messagingCentre,
    theme,
    action,
    attachments,
    taxonomy,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware();
    // .concat(loggerMiddleware) // should use .concat rather than spread - see https://redux-toolkit.js.org/api/getDefaultMiddleware#intended-usage
  },
  // we need to double-check that we're handling NODE_ENV correctly
  // https://docs.netlify.com/configure-builds/manage-dependencies/#node-js-environment
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
