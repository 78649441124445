import React, { useState, useEffect, memo } from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import ScheduleIcon from '@mui/icons-material/Schedule';
import './style.scss';

import { NewSocialShareModal } from 'components/SocialShareButton';
import { formatDate, getMomentWithTimezone } from '../../../utils/time';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import useGetJoinCall from '../../../hooks/useGetJoinCall';
import { GroupEvent, BoostCallEventWithVolunteerProfile } from '../../../types';
import MessagesModal from 'components/MessagesModal';
import RescheduleUpcomingCard from 'components/RescheduleUpcomingCard';

import '../components/styling/card-styling.scss';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import moment from 'moment';
import CancelRescheduleModal from 'components/CancelRescheduleModal';
import { toTitleCase } from 'utils/text';
import MentorModal from 'components/MentorCard/MentorModal';
import api from 'api';
import { Mentor } from '../../../../types';
import { DashboardEmptyButton, DashboardFilledButton } from 'styling/buttons';
import { StyledBadge } from 'styling/generalStyling';
import { useSystemTogglesContext } from 'context/SystemToggles';

import { useSelector } from 'react-redux';
import { selectSessionTitle } from 'features/reduxStore/sessionTitle';

type Props = {
  appointmentData: any;
  timeZone?: string;
  subject: string;
  refetchEvents?: any;
  clickManage?: (e: any) => void;
};
const baseUrl = process.env.REACT_APP_BASE_URL;
const modalStyle = {
  paddingTop: '10px',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: '8px',
  transform: 'translate(-50%, -50%)',
  width: ' 554px',
  height: '250px',
  margin: '9px 29px 217px 76px',
  bgcolor: ' #fff',
  boxShadow: 24,
  p: 4,
};

const NewUpcomingCard = ({
  timeZone,
  appointmentData,
  refetchEvents,
}: Props) => {
  const { toggles } = useSystemTogglesContext();
  const showMessagingUI = toggles['NEW_MESSAGING_UI'];

  const navigate = useNavigate();
  const { joinCall } = useGetJoinCall({ callInfo: appointmentData });

  const {
    id: boostCallId,
    organisation_new_messages_count,
    start_time,
    title,
    volunteer: volunteerData,
  } = (appointmentData as BoostCallEventWithVolunteerProfile) || {};

  const {
    interest,
    code,
    duration,
    start_time: groupEventDate,
    volunteer: groupEventVolunteerProfile,
    type: groupEventType,
  } = (appointmentData as GroupEvent) || {};

  const id = code || boostCallId;

  const speakerBriefing = `/group-event-details?code=${code}`;
  const groupEventDetailsLink = `group-event-details?code=${code}`;

  const profileData = {
    name: volunteerData?.first_name,
    picture: volunteerData?.profile_picture,
    companyName: volunteerData?.employer,
    currentRole: volunteerData?.current_role,
    showPresentedBy: false,
  };
  const [openMentorModal, setOpenMentorModal] = useState<boolean>(false);

  const dateFormatted = formatDate(start_time, timeZone);

  const time = {
    time: dateFormatted?.time,
    date: dateFormatted?.date,
  };

  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const [openMessagesModal, setOpenMessagesModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const handleOpenShareModal = () => setOpenShareModal(true);
  const handleCloseShareModal = () => setOpenShareModal(false);
  const handleOpenCancelModal = () => setCancelModalOpen(true);
  const handleCloseCancelModal = () => setCancelModalOpen(false);

  useEffect(() => {
    setUnreadMessages(organisation_new_messages_count);
  }, [organisation_new_messages_count]);

  const [mentor, setMentor] = useState<Mentor | undefined>(undefined);
  const closeModal = () => {
    setMentor(undefined);
    setOpenMentorModal(false);
  };
  const openModal = async () => {
    const { results } = await api.searchMentors([volunteerData?.id], 1);
    if (results && results.length && results.length > 0) {
      setMentor(results[0]);
      setOpenMentorModal(true);
    }
  };

  const durationUntilEventStarts = moment.duration(
    getMomentWithTimezone(groupEventDate, timeZone).diff(moment())
  );
  const sessionTitle = useSelector(selectSessionTitle);
  const hoursUntilEventStarts = durationUntilEventStarts.asHours();
  const lessThan30MinutesUntilGroupEvent = !(
    hoursUntilEventStarts < -1 || hoursUntilEventStarts > 0.5
  );
  const timezoneToUse = timeZone || moment.tz.guess(true);
  const endTimeIsInPast = moment().isAfter(
    getMomentWithTimezone(start_time, timezoneToUse).add(
      duration || 60,
      'minutes'
    )
  );
  if (title === '1-to-1 mentoring call')
    return (
      <div className="dashboard-box">
        <Box
          sx={{
            '& > :not(style)': {
              width: '100%',
              height: '100%',
            },
          }}
        >
          <Paper
            key={id}
            sx={{
              borderRadius: '8px',
              boxShadow: '8px 5px 30px 0 rgba(0, 0, 0, 0.06)',
            }}
          >
            <div>
              <div className="rectangle-icon-container">
                <div className="upcoming-rectangle-icon">
                  <div className="rectangle-icon-text">Upcoming</div>
                </div>
              </div>
              <div className="dashboard-card__container">
                <div
                  onClick={() => openModal()}
                  className="profile-photo-card"
                  style={{
                    cursor: 'pointer',
                    backgroundImage: profileData.picture
                      ? `url(${profileData.picture})`
                      : 'url(/avatar.png)',
                  }}
                ></div>
                {mentor && openMentorModal ? (
                  <MentorModal
                    mentor={mentor}
                    modalOpen={Boolean(mentor)}
                    closeModal={closeModal}
                    hideSelect
                  />
                ) : null}
                <div className="dashboard-card-header-info">
                  <div className="dashboard-card-title">
                    <div className="dashboard-card-name">
                      {sessionTitle} with
                      <div className="upcoming-paper-first-name">
                        {profileData.name}
                      </div>
                    </div>
                  </div>
                  <div className="upcoming-paper-organisation">
                    {profileData.companyName}
                  </div>
                  <div className="upcoming-paper-topic">
                    {' '}
                    {appointmentData.top_priority_name}
                  </div>
                  <div className="upcoming-paper-time">
                    <div className="dashboard-paper-time-day">
                      <CalendarTodayOutlinedIcon
                        sx={{ paddingBottom: '3px' }}
                        fontSize="small"
                      />{' '}
                      {time.date?.day} {time?.date?.monthThreeLetterFormat}{' '}
                      {time?.date?.year}
                    </div>
                    <div className="dashboard-paper-time-hour">
                      <ScheduleIcon
                        fontSize="small"
                        sx={{ paddingBottom: '3px' }}
                      />{' '}
                      {time.time?.hour}{' '}
                    </div>
                  </div>
                  <div className="upcoming-paper-button-wrapper">
                    <div className="dashboard-button-container">
                      <DashboardFilledButton
                        sx={{ width: '150px' }}
                        variant="contained"
                        onClick={() => joinCall()}
                      >
                        Join Session
                      </DashboardFilledButton>
                    </div>
                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        sx={{ color: '#333333', width: 'fit-content' }}
                        variant="text"
                        onClick={() => {
                          if (showMessagingUI) {
                            navigate(
                              `/messaging-centre?id=${appointmentData?.volunteer?.id}`
                            );
                            return;
                          }
                          refetchEvents();
                          setUnreadMessages(0);
                          setOpenMessagesModal(true);
                        }}
                      >
                        <StyledBadge
                          badgeContent={showMessagingUI ? 0 : unreadMessages}
                          color="info"
                        >
                          Messages
                        </StyledBadge>
                      </DashboardEmptyButton>
                    </div>
                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        sx={{ color: '#333333' }}
                        onClick={() => setRescheduleModal(true)}
                        disabled={moment().isAfter(moment(start_time))}
                      >
                        Reschedule
                      </DashboardEmptyButton>
                    </div>{' '}
                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        sx={{ color: '#333333' }}
                        onClick={() => {
                          navigate(
                            `/call-details?cid=${appointmentData.id}&type=events&event_update_options=true`
                          );
                        }}
                      >
                        Manage
                      </DashboardEmptyButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </Box>
        {openMessagesModal ? (
          <MessagesModal
            boostCallEvent={
              appointmentData as BoostCallEventWithVolunteerProfile
            }
            onReadMessages={() => setUnreadMessages(0)}
            onClose={() => setOpenMessagesModal(false)}
            openMessagesModal={openMessagesModal}
          />
        ) : null}
        <RescheduleUpcomingCard
          profileData={profileData}
          timeZone={timeZone}
          id={id}
          time={time}
          setRescheduleModal={setRescheduleModal}
          rescheduleModal={rescheduleModal}
        />
      </div>
    );
  else
    return (
      <div className="dashboard-box">
        <Box
          sx={{
            '& > :not(style)': {
              width: '100%',
              height: '100%',
            },
          }}
        >
          <Paper
            key={id}
            sx={{
              borderRadius: '8px',
              boxShadow: '8px 5px 30px 0 rgba(0, 0, 0, 0.06)',
            }}
          >
            <div>
              <div className="rectangle-icon-container">
                <div className="upcoming-rectangle-icon">
                  <div className="rectangle-icon-text">Upcoming</div>
                </div>
              </div>
              <div className="dashboard-card__container">
                <img
                  className="mastercard-photo-card"
                  src={'/masterclass-icon.png'}
                  alt={'masterclass'}
                />
                <div className="dashboard-card-header-info">
                  <div className="dashboard-card-title">
                    <div className="dashboard-card-name">
                      Upcoming {toTitleCase(groupEventType)}
                    </div>
                  </div>
                  <div className="upcoming-paper-topic">
                    {appointmentData?.title || interest.name}
                  </div>

                  <div className="upcoming-paper-time">
                    <div className="dashboard-paper-time-day">
                      <CalendarTodayOutlinedIcon
                        fontSize="small"
                        sx={{ paddingBottom: '3px' }}
                      />{' '}
                      {time.date?.day} {time?.date?.monthThreeLetterFormat}{' '}
                      {time?.date?.year}
                    </div>
                    <div className="dashboard-paper-time-hour">
                      <ScheduleIcon
                        fontSize="small"
                        sx={{ paddingBottom: '3px' }}
                      />{' '}
                      {time.time?.hour}{' '}
                    </div>
                  </div>
                  <div className="upcoming-paper-button-wrapper">
                    <div className="dashboard-button-container">
                      <DashboardFilledButton
                        variant="contained"
                        sx={{ width: '160px' }}
                        onClick={() => joinCall()}
                        disabled={
                          !lessThan30MinutesUntilGroupEvent || endTimeIsInPast
                        }
                      >
                        {endTimeIsInPast
                          ? 'Finished'
                          : `Join ${toTitleCase(groupEventType)}`}
                      </DashboardFilledButton>
                    </div>
                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        sx={{ color: '#333333', width: '90px' }}
                        onClick={() => {
                          navigate(speakerBriefing);
                        }}
                      >
                        Details
                      </DashboardEmptyButton>
                    </div>
                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        sx={{ color: '#333333', width: '90px' }}
                        onClick={handleOpenShareModal}
                      >
                        Share
                      </DashboardEmptyButton>
                    </div>
                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        sx={{ color: '#ff5f5f', width: '90px' }}
                        onClick={handleOpenCancelModal}
                      >
                        Cancel
                      </DashboardEmptyButton>
                    </div>
                    <Modal
                      open={openShareModal}
                      onClose={handleCloseShareModal}
                      aria-labelledby="group-event-modal-title"
                    >
                      <Box sx={modalStyle}>
                        <NewSocialShareModal
                          link={`${baseUrl}${groupEventDetailsLink}`}
                          eventType={groupEventType}
                          subject={
                            appointmentData.interest.name ||
                            appointmentData.title
                          }
                          name={`${groupEventVolunteerProfile?.first_name} ${
                            groupEventVolunteerProfile?.last_name
                              ? groupEventVolunteerProfile?.last_name
                              : ''
                          }`}
                          companyName={groupEventVolunteerProfile?.employer}
                          startTime={groupEventDate}
                          onCloseClick={handleCloseShareModal}
                        />
                      </Box>
                    </Modal>
                    {cancelModalOpen && (
                      <CancelRescheduleModal
                        boostCall={
                          appointmentData as
                            | BoostCallEventWithVolunteerProfile
                            | GroupEvent
                        }
                        isGroupEvent={Boolean(groupEventType)}
                        onDecline={() => refetchEvents && refetchEvents()}
                        // onCancelGroupEventAttendance={() => ({})}
                        onClose={handleCloseCancelModal}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </Box>
      </div>
    );
};

export default memo(NewUpcomingCard);
