import React from 'react';
import './select-org-type.scss';

interface Props {
  orgType: any;
  setOrgType: any;
}

const SelectOrgType = ({ orgType, setOrgType }: Props) => {
  return (
    <div className="select-org-type">
      <label>What type of organisation do you work for?</label>

      <div>
        <span>
          <input
            type="radio"
            value="business"
            checked={orgType === 'business'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOrgType(e.target.value)
            }
          />
          <span>Business</span>
        </span>

        <span>
          <input
            type="radio"
            value="charity"
            checked={orgType === 'charity'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOrgType(e.target.value)
            }
          />

          <span>Charity</span>
        </span>
        <span>
          <input
            type="radio"
            value="social_enterprise"
            checked={orgType === 'social_enterprise'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOrgType(e.target.value)
            }
          />

          <span>Social Enterprise</span>
        </span>
        <span>
          <input
            type="radio"
            value="educational_institute"
            checked={orgType === 'educational_institute'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOrgType(e.target.value)
            }
          />

          <span>School / College / University</span>
        </span>

        <span>
          <input
            type="radio"
            value="freelancer"
            checked={orgType === 'freelancer'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOrgType(e.target.value)
            }
          />
          <span>Freelancer</span>
        </span>
        <span>
          <input
            type="radio"
            value="student"
            checked={orgType === 'student'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOrgType(e.target.value)
            }
          />

          <span>Student</span>
        </span>
        <span>
          <input
            type="radio"
            value="health_service"
            checked={orgType === 'health_service'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOrgType(e.target.value)
            }
          />

          <span>Health Services</span>
        </span>
        <span>
          <input
            type="radio"
            value="unemployed_furloughed"
            checked={orgType === 'unemployed_furloughed'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setOrgType(e.target.value)
            }
          />

          <span>Unemployed / Furloughed</span>
        </span>
      </div>
    </div>
  );
};

export default SelectOrgType;
