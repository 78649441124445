import React, { memo, useState } from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import ScheduleIcon from '@mui/icons-material/Schedule';
import './style.scss';
import { NewSocialShareModal } from 'components/SocialShareButton';
import { formatDate, getMomentWithTimezone } from '../../../utils/time';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import { GroupEvent } from '../../../types';

import '../components/styling/card-styling.scss';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { DashboardFilledButton, DashboardEmptyButton } from 'styling/buttons';
import moment from 'moment';
import useSelectProfileData from 'hooks/useSelectProfileData';

type Props = {
  appointmentData: any;
  timeZone?: string;
};
const baseUrl = process.env.REACT_APP_BASE_URL;
const modalStyle = {
  paddingTop: '10px',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: '8px',
  transform: 'translate(-50%, -50%)',
  width: ' 554px',
  height: '250px',
  margin: '9px 29px 217px 76px',
  bgcolor: ' #fff',
  boxShadow: 24,
  p: 4,
};
const NewRecommendationCard = ({ appointmentData, timeZone }: Props) => {
  const navigate = useNavigate();
  const { userLocation } = useSelectProfileData();
  const { id, code, type, interest, volunteer, start_time, title } =
    (appointmentData as GroupEvent) || {};
  const speakerBriefing = `/group-event-details?code=${code}`;
  const groupEventDetailsLink = `group-event-details?code=${code}`;
  const groupEventRegisterLink = `/group-event-details?code=${code}&register=true`;

  const dateFormatted = formatDate(start_time, timeZone);

  const time = {
    time: dateFormatted?.time,
    date: dateFormatted?.date,
  };
  const { timezone } = userLocation || {};
  const timezoneToUse = timezone || moment.tz.guess(true);

  const RegisterButtonEnabled = moment().isBefore(
    getMomentWithTimezone(start_time, timezoneToUse).add(45, 'minutes')
  );

  const [openShareModal, setOpenShareModal] = useState(false);

  const handleOpenShareModal = () => setOpenShareModal(true);
  const handleCloseShareModal = () => setOpenShareModal(false);

  return (
    <div className="dashboard-box">
      <Box
        sx={{
          '& > :not(style)': {
            width: '100%',
            height: '100%',
          },
        }}
      >
        <Paper
          key={id}
          sx={{
            borderRadius: '8px',
            boxShadow: '8px 5px 30px 0 rgba(0, 0, 0, 0.06)',
          }}
        >
          <div>
            <div className="recommendation-card__container">
              <img
                className="mastercard-photo-card"
                src={'/masterclass-icon.png'}
                alt={'masterclass'}
              />
              <div className="dashboard-card-header-info">
                <div className="dashboard-card-title">
                  <div className="dashboard-card-name">
                    {type === 'masterclass'
                      ? 'Masterclass'
                      : type === 'workshop'
                      ? 'Workshop'
                      : 'Webinar'}
                  </div>
                </div>
                <div className="upcoming-paper-topic">
                  {title || interest?.name}
                </div>
                <div className="recommendation-card__volunteer">
                  <div>by</div>
                  <div className="profile-photo-container">
                    <img src={volunteer?.profile_picture} alt="Volunteer" />
                  </div>
                  <strong>{volunteer?.first_name}</strong>
                </div>

                <div className="upcoming-paper-time">
                  <div className="dashboard-paper-time-day">
                    <CalendarTodayOutlinedIcon
                      fontSize="small"
                      sx={{ paddingBottom: '3px' }}
                    />{' '}
                    {time.date?.day} {time.date?.monthThreeLetterFormat}{' '}
                    {time.date?.year}
                  </div>
                  <div className="dashboard-paper-time-hour">
                    <ScheduleIcon
                      fontSize="small"
                      sx={{ paddingBottom: '3px' }}
                    />{' '}
                    {time.time?.hour}
                  </div>
                </div>
                <div className="upcoming-paper-button-wrapper">
                  <div className="dashboard-button-container">
                    <DashboardFilledButton
                      sx={{ width: '120px', marginRight: '25px' }}
                      variant="contained"
                      disabled={!RegisterButtonEnabled}
                      onClick={() => navigate(groupEventRegisterLink)}
                    >
                      Register
                    </DashboardFilledButton>
                  </div>
                  <div className="dashboard-link-button-container">
                    <DashboardEmptyButton
                      variant="text"
                      sx={{ marginRight: '10px', width: '120px' }}
                      onClick={() => navigate(speakerBriefing)}
                    >
                      Read more
                    </DashboardEmptyButton>
                  </div>
                  <div className="dashboard-link-button-container">
                    <DashboardEmptyButton
                      variant="text"
                      sx={{ marginRight: '10px' }}
                      onClick={handleOpenShareModal}
                    >
                      Share
                    </DashboardEmptyButton>
                  </div>
                  <Modal
                    open={openShareModal}
                    onClose={handleCloseShareModal}
                    aria-labelledby="group-event-modal-title"
                  >
                    <Box sx={modalStyle}>
                      <NewSocialShareModal
                        link={`${baseUrl}${groupEventDetailsLink}`}
                        eventType={type}
                        subject={interest.name}
                        name={`${volunteer?.first_name} ${
                          volunteer?.last_name ? volunteer?.last_name : ''
                        }`}
                        companyName={volunteer?.employer}
                        startTime={start_time}
                        onCloseClick={handleCloseShareModal}
                      />
                    </Box>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </Box>
    </div>
  );
};

export default memo(NewRecommendationCard);
