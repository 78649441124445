import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import {
  CustomFormControlLabel,
  CustomTypography,
} from 'styling/generalStyling';

import '../style/profile.css';

type Props = {
  setValue: (value: any, input: string) => void;
  value?: string;
};
export default function EditOrganisationTypeForm({ value, setValue }: Props) {
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="gender-radio-buttons-group-label"
        defaultValue={value}
        name="radio-buttons-group"
        sx={{
          '& .Mui-checked': {
            color: '#7FFFD4',
          },
        }}
      >
        <CustomFormControlLabel
          label={<CustomTypography>Business</CustomTypography>}
          onClick={() => setValue('business', 'org_type')}
          control={<Radio />}
          value="business"
        />
        <CustomFormControlLabel
          label={<CustomTypography>Charity</CustomTypography>}
          onClick={() => setValue('charity', 'org_type')}
          control={<Radio />}
          value="charity"
        />
        <CustomFormControlLabel
          label={<CustomTypography>Social Enterprise</CustomTypography>}
          onClick={() => setValue('social_enterprise', 'org_type')}
          control={<Radio />}
          value="social_enterprise"
        />
        <CustomFormControlLabel
          label={
            <CustomTypography>School / College / University</CustomTypography>
          }
          onClick={() => setValue('educational_institute', 'org_type')}
          control={<Radio />}
          value="educational_institute"
        />
        <CustomFormControlLabel
          label={<CustomTypography>Freelancer</CustomTypography>}
          onClick={() => setValue('freelancer', 'org_type')}
          control={<Radio />}
          value="freelancer"
        />

        <CustomFormControlLabel
          label={<CustomTypography>Student</CustomTypography>}
          onClick={() => setValue('student', 'org_type')}
          control={<Radio />}
          value="student"
        />
        <CustomFormControlLabel
          label={<CustomTypography>Health Services</CustomTypography>}
          onClick={() => setValue('health_service', 'org_type')}
          control={<Radio />}
          value="health_service"
        />
        <CustomFormControlLabel
          label={<CustomTypography>Unemployed / Furloughed</CustomTypography>}
          onClick={() => setValue('unemployed_furloughed', 'org_type')}
          control={<Radio />}
          value="unemployed_furloughed"
        />
      </RadioGroup>
    </FormControl>
  );
}
