import React from 'react';
import './input-with-label.scss';

interface Props extends React.HTMLProps<HTMLInputElement> {
  label?: string | undefined;
  placeHolder?: string | undefined;
  onInput?: any;
  type: string;
  defaultValue?: any;
  readOnly?: boolean;
  mandatory?: boolean;
  maxLength?: number;
  containerClassName?: string;
}

const InputWithLabel = ({
  type,
  label,
  placeHolder,
  defaultValue,
  maxLength,
  mandatory,
  onInput = () => {
    console.log('onInput');
  },
  containerClassName,
  ...props
}: Props) => {
  const labelForTesting =
    label &&
    label
      .split(' ')
      .map((s) => s.toLowerCase())
      .join('-');
  const inputDataCy = labelForTesting
    ? { 'data-cy': `input-${labelForTesting}` }
    : {};
  return (
    <div
      className={`input-with-label ${
        containerClassName ? containerClassName : ''
      }`}
    >
      {label && (
        <label data-cy={`input-label-${labelForTesting}`}>
          {label}{' '}
          {mandatory && (
            <span className="input-with-label__mandatory-asterisk">
              {'\u002A '}
            </span>
          )}
        </label>
      )}

      <input
        onInput={onInput}
        placeholder={placeHolder}
        type={type}
        defaultValue={defaultValue}
        maxLength={maxLength}
        required={mandatory}
        {...inputDataCy}
        {...props}
      />
    </div>
  );
};

export default InputWithLabel;
