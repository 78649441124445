import React, { useState } from 'react';
import './statistics.scss';
import { IconButton, Paper, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

type Props = {
  masterclasses: number;
  topInterests: any[];
};

export default function StatisticsChart({
  masterclasses,
  topInterests,
}: Props) {
  const [showTooltip1, setShowTooltip1] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);

  const organisationsBadge = (count: number) => {
    if (count > 0 && count < 6) return <div>💪 Going good</div>;
    if (count > 5 && count < 11) return <div>😎 You are committed</div>;
    if (count > 10) return <div>🤩 You are amazing</div>;
    else return null;
  };
  return (
    <div className="statistics-container">
      <div className="statistics-paper-box">
        <Paper key={1} elevation={0}>
          <div className="statistics-tooltip">
            <Tooltip
              open={showTooltip1}
              onMouseEnter={() => setShowTooltip1(true)}
              onMouseLeave={() => setShowTooltip1(false)}
              title="These are the top subjects you received mentorship for so far."
            >
              <IconButton sx={{ fontSize: 'small' }}>
                <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
              </IconButton>
            </Tooltip>
          </div>
          <div className="statistics-paper-container">
            <div className="organisations-helped-photo-card-container">
              <img
                className="organisations-helped-photo-card"
                src={'/top-subjects.svg'}
                alt={'organisations-helped'}
              />
            </div>
            <div className="statistics-paper-info">
              {topInterests &&
                topInterests?.slice(0, 2).map((interest, idx) => (
                  <div className="subjects-text" key={idx}>
                    {interest?.top_priority}{' '}
                    <span>{interest?.total} sessions</span>
                  </div>
                ))}
              <div className="statistics-reward">
                <div className="statistics-title">Top subjects</div>
                <div className="statistics-badge">
                  {organisationsBadge(topInterests.length)}
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </div>
      <div className="statistics-paper-box">
        <Paper key={2} elevation={0}>
          <div className="statistics-tooltip">
            <Tooltip
              open={showTooltip2}
              onMouseEnter={() => setShowTooltip2(true)}
              onMouseLeave={() => setShowTooltip2(false)}
              title="These are the number of masterclasses attended."
            >
              <IconButton sx={{ fontSize: 'small' }}>
                <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
              </IconButton>
            </Tooltip>
          </div>
          <div className="statistics-paper-container">
            <div className="masterclass-statistics-photo-card-container">
              <img
                className="masterclass-statistics-photo-card"
                src={'/masterclass-icon.png'}
                alt={'organisations-helped'}
              />
            </div>
            <div className="statistics-paper-info">
              <div className="statistics-count">{masterclasses}</div>
              <div className="statistics-reward">
                <div className="statistics-title">Masterclasses attended</div>
                <div className="statistics-badge">
                  {organisationsBadge(masterclasses)}
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
}
