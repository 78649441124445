import React, { useState, useRef, memo, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Hamburger from 'hamburger-react';
import TimezoneIndicator from '../TimezoneIndicator';
import useWindowSize from '../../hooks/useWindowSize';
import useWindowScrollPosition from '../../hooks/useWindowScrollPosition';
import useAuth from '../../features/auth/hooks/useAuth';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import { getUrlParam } from '../../utils/url';
import './new-nav.scss';
import useClickOutsideContainer from '../../hooks/useClickOutsideContainer';
import Logo from 'components/Nav/Logo';
import { DashboardEmptyButton, DashboardFilledButton } from 'styling/buttons';
import { updateTheme } from 'features/reduxStore/theme/theme';
import useRedux from 'hooks/useRedux';
import { Alert, Tooltip } from '@mui/material';
import useFetchPartner from 'features/partner/hooks/useFetchPartner';
import MessagingIndicator from 'components/MessagingIndicator';
import { setSessionTitle } from 'features/reduxStore/sessionTitle';
import Cookie from 'js-cookie';
import Button from '../Button';
import qs from 'qs';
import FaqIndicator from 'components/FaqIndicator';
import { useSystemTogglesContext } from 'context/SystemToggles';
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';

const Nav = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { logout } = useAuth();
  const wrapperRef = useRef(null);

  const { width = window.innerWidth } = useWindowSize();
  const isMobile = width && width < 750;
  const showHamburger = width < 1200;
  const { y = window.pageYOffset } = useWindowScrollPosition();

  const navExclusionArray = [
    '/premium-partner',
    '/partner-login',
    '/premium-partner-preferences',
    !getUrlParam('custom_call_url') && '/boost-call',
  ];

  const [menuOpen, setMenuOpen] = useState(false);
  const {
    organisationProfile,
    userProfile,
    hasFinishedFetchingProfileAndLocation,
  } = useSelectProfileData();
  const closeMenuAndExecute = (func: () => void) => {
    return () => {
      setMenuOpen(false);
      func();
    };
  };
  const handleClickOutsideMenu = () => {
    if (menuOpen) {
      setMenuOpen(false);
    }
  };
  const { dispatch } = useRedux();
  const { partner: fetchPartnerResponseData } = useFetchPartner();

  useClickOutsideContainer(wrapperRef, handleClickOutsideMenu);
  useEffect(() => {
    if (fetchPartnerResponseData?.white_label) {
      var PartnerTheme = {
        palette: {
          primary: {
            main: fetchPartnerResponseData?.primary_color || '#583372',
            light: fetchPartnerResponseData?.tertiary_color || '#f2f2f2',
            dark: '#f2f2f2',
            contrastText:
              fetchPartnerResponseData?.contrast_text_primary || '#FFFFFF',
          },
          secondary: {
            main: fetchPartnerResponseData?.secondary_color || '#f7f7f7',
          },
        },
      };
      dispatch(updateTheme(PartnerTheme));
      if (fetchPartnerResponseData?.session_title)
        dispatch(setSessionTitle(fetchPartnerResponseData?.session_title));
      else dispatch(setSessionTitle('1:1 Session'));
    } else dispatch(setSessionTitle('1:1 Session'));
  }, [fetchPartnerResponseData, dispatch]);
  const goDashboard = () => {
    navigate('/dashboard/actions');
  };
  const goBookings = () => {
    navigate('/dashboard/mentoring');
  };
  const goProgress = () => {
    navigate('/dashboard/your-progress');
  };
  const goReplays = () => {
    navigate('/dashboard/events');
  };
  const goActionPlan = () => {
    navigate('/dashboard/actionplan');
  };
  const goRecommendation = () => {
    navigate('/dashboard/recommendations');
  };
  const goResources = () => {
    navigate('/dashboard/resources');
  };
  const goMentoring = () => {
    navigate('/mentor-select');
  };
  const goAccount = () => {
    navigate('/account');
  };
  const goPreferences = () => {
    navigate('/preferences');
  };
  const { toggles } = useSystemTogglesContext();
  const showAttachmentCenter = toggles['ATTACHMENT_CENTRE'];
  const showActionPlan = toggles['ACTION_PLAN'];
  const handleSignupClick = useCallback(() => {
    if (pathname === '/group-event-details') {
      const inAnHour = 1 / 24;
      const searchWithoutQuery = search.match(/\?(.*)/)?.[1];
      const groupEventCode =
        searchWithoutQuery && qs.parse(searchWithoutQuery)?.code;
      groupEventCode &&
        Cookie.set('groupEventSignup', groupEventCode, { expires: inAnHour });
    }
    navigate('/signup');
  }, [pathname, navigate, search]);

  if (navExclusionArray.indexOf(pathname) > -1) return null;

  const renderLoginAndSignupButtons = () => (
    <>
      {pathname !== '/login' && (
        <div className="nav__login-button-wrapper">
          <div
            onClick={closeMenuAndExecute(() => {
              navigate('/login');
            })}
            className="quick-link nav__logged-out-link"
          >
            Login
          </div>
        </div>
      )}
      {pathname.includes('/mentor-select') && (
        <Button
          type="primary"
          className="nav__login-button nav__logged-out-link"
          onClickFunction={closeMenuAndExecute(handleSignupClick)}
        >
          <div>Sign up - it's free</div>
        </Button>
      )}
    </>
  );

  const showNavShadow = () => {
    if (
      pathname.includes('mentor-select') ||
      pathname.includes('select-availability')
    ) {
      return false;
    }
    return true;
  };

  const showBookSession = () => {
    if (
      pathname.includes('mentor-select') ||
      pathname.includes('select-availability') ||
      pathname.includes('book-session') ||
      pathname.includes('volunteer-details')
    ) {
      return false;
    }
    return true;
  };

  const showGoToDashboard = () => {
    if (pathname.includes('volunteer-details')) {
      return true;
    }
    return false;
  };

  if (
    (organisationProfile === undefined || organisationProfile === null) &&
    !window.location.href.includes('/login') &&
    !window.location.href.includes('/signup') &&
    !window.location.href.includes('/redirect') &&
    !window.location.href.includes('/mentor-select') &&
    !window.location.href.includes('/dashboard') &&
    !window.location.href.includes('/volunteer-details') &&
    !window.location.href.includes('/group-event-details') &&
    !window.location.href.includes('/select-availability') &&
    !window.location.href.includes('/partner-integrated-signup')
  )
    return (
      <div
        className="nav-no-profile"
        style={{
          display: 'inline-flex',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <Alert
          severity="info"
          sx={{
            fontFamily: 'Gilroy',
            fontSize: isMobile ? '14px' : '18px',
            display: 'inline-flex',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          Please complete your profile to gain access to our platform! If you
          drop before the completion your data will not be saved.
        </Alert>
        <DashboardEmptyButton
          color="info"
          variant="outlined"
          sx={{ height: '54px', color: '#333' }}
          onClick={closeMenuAndExecute(logout)}
          className="nav__menu-box-link"
        >
          Logout
        </DashboardEmptyButton>
      </div>
    );
  else
    return (
      <>
        <nav className={`nav ${showNavShadow() && y > 0 ? 'nav__shadow' : ''}`}>
          <div className="nav-container">
            <Logo footer={false} />
            {hasFinishedFetchingProfileAndLocation ? (
              <div className="nav-link-container">
                {organisationProfile ? (
                  <>
                    {!showHamburger && showGoToDashboard() && (
                      <>
                        <DashboardFilledButton
                          sx={{ width: '200px' }}
                          variant="contained"
                          onClick={() => {
                            navigate('/dashboard/actions');
                          }}
                        >
                          Dashboard
                        </DashboardFilledButton>
                      </>
                    )}
                    {!showHamburger && showBookSession() && (
                      <>
                        <DashboardFilledButton
                          sx={{ width: '200px' }}
                          variant="contained"
                          onClick={() => {
                            navigate('/mentor-select');
                          }}
                        >
                          Request a session
                        </DashboardFilledButton>
                      </>
                    )}
                    <div
                      className="nav-link nav__burger-menu-container"
                      ref={wrapperRef}
                    >
                      <div className="nav__profile-wrapper">
                        <TimezoneIndicator
                          className="nav__timezone-indicator"
                          newDashboard
                        />
                        <FaqIndicator
                          className="nav__faq-indicator"
                          partner={fetchPartnerResponseData}
                        />
                        {showAttachmentCenter ? (
                          <Tooltip
                            title="Manage your files"
                            placement="bottom"
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  width: '200px',
                                  fontFamily: 'Gilroy',
                                  padding: '10px',
                                  borderRadius: '3px',
                                  fontWeight: '500',
                                  fontSize: '16px',
                                  textAlign: 'center',

                                  boxShadow: ' 0px 1px 6px rgba(0, 0, 0, 0.3)',
                                  bgcolor: 'white',
                                  color: 'black',
                                  '& .MuiTooltip-arrow': {
                                    color: 'black',
                                  },
                                },
                              },
                            }}
                          >
                            <TopicOutlinedIcon
                              fontSize="medium"
                              style={{ cursor: 'pointer' }}
                              className="nav__faq-indicator"
                              onClick={() => navigate('.library')}
                            />
                          </Tooltip>
                        ) : // keeping this code for the new app styling of the icon on attachment center
                        //  <AttachmentButton
                        //   onClick={() => navigate('/library')}
                        // />
                        null}
                        <MessagingIndicator className="nav__messaging-indicator" />
                        <div
                          className="nav__pic-and-name"
                          onClick={closeMenuAndExecute(goAccount)}
                        >
                          <div className="profile-pic">
                            {organisationProfile.profile_picture && (
                              <img
                                height={'31px'}
                                width={'31x'}
                                alt="avatar"
                                src={organisationProfile.profile_picture}
                              />
                            )}
                            {!organisationProfile.profile_picture && (
                              <img
                                height={'31px'}
                                alt="avatar"
                                src={'/avatar.png'}
                              />
                            )}
                          </div>
                        </div>
                        <Hamburger
                          toggled={menuOpen}
                          toggle={setMenuOpen}
                          size={20}
                        />
                      </div>
                      {menuOpen && (
                        <div className="menu-box">
                          <div
                            onClick={closeMenuAndExecute(goDashboard)}
                            className="nav__menu-box-link"
                            data-cy="Dashboard-link"
                          >
                            Dashboard
                          </div>
                          {showHamburger && (
                            <div
                              onClick={closeMenuAndExecute(goMentoring)}
                              className="nav__menu-box-link"
                              data-cy="Mentoring-link"
                            >
                              Book a session
                            </div>
                          )}

                          {showHamburger && (
                            <>
                              <div
                                onClick={closeMenuAndExecute(goBookings)}
                                className="nav__menu-box-link"
                                data-cy="Dashboard-link"
                              >
                                Mentoring
                              </div>
                              <div
                                onClick={closeMenuAndExecute(goReplays)}
                                className="nav__menu-box-link"
                                data-cy="Dashboard-link"
                              >
                                Events
                              </div>
                              {showActionPlan ? (
                                <div
                                  onClick={closeMenuAndExecute(goActionPlan)}
                                  className="nav__menu-box-link"
                                  data-cy="Dashboard-link"
                                >
                                  Action Plan
                                </div>
                              ) : null}
                              <div
                                onClick={closeMenuAndExecute(goRecommendation)}
                                className="nav__menu-box-link"
                                data-cy="Dashboard-link"
                              >
                                Recommendation
                              </div>
                              <div
                                onClick={closeMenuAndExecute(goResources)}
                                className="nav__menu-box-link"
                                data-cy="Resouces-link"
                              >
                                Resources
                              </div>
                              <div
                                onClick={closeMenuAndExecute(goProgress)}
                                className="nav__menu-box-link"
                                data-cy="Dashboard-link"
                              >
                                My Progress
                              </div>
                              <div
                                onClick={closeMenuAndExecute(goPreferences)}
                                className="nav__menu-box-link"
                                data-cy="Dashboard-link"
                              >
                                Preferences
                              </div>
                              <div
                                onClick={closeMenuAndExecute(goAccount)}
                                className="nav__menu-box-link"
                                data-cy="Dashboard-link"
                              >
                                Account
                              </div>
                            </>
                          )}

                          <div
                            onClick={closeMenuAndExecute(logout)}
                            className="nav__menu-box-link"
                            data-cy="Logout-button"
                          >
                            Logout
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div
                    className="nav__logged-out-link-container"
                    ref={wrapperRef}
                  >
                    {showHamburger && (
                      <Hamburger
                        toggled={menuOpen}
                        toggle={setMenuOpen}
                        size={20}
                      />
                    )}
                    {(!showHamburger || menuOpen) && (
                      <div className="nav__logged-out-link-wrapper">
                        {!userProfile ? (
                          renderLoginAndSignupButtons()
                        ) : (
                          <div
                            onClick={closeMenuAndExecute(logout)}
                            className="quick-link nav__logged-out-link"
                          >
                            Logout
                          </div>
                        )}
                        {!showHamburger && (
                          <div style={{ height: '48px' }}></div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="nav-link-container">
                <div
                  style={{ height: '48px' }}
                  className="quick-link nav__hidden"
                >
                  .
                </div>
              </div>
            )}
          </div>
        </nav>
        <div className="nav__sizing-placeholder" />
      </>
    );
};

export default memo(Nav);
