import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import EditInput from './EditInput';
import { PrivacyPreferences, VolunteerProfile } from '../types';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import '../style/general.css';
import Cookie from 'js-cookie';
import { CustomFormLabel } from 'styling/generalStyling';
import apiInstance from '../../../api/config';
import useAuth from '../../../features/auth/hooks/useAuth';
import {
  CustomProfileCancelButton,
  CustomProfileSaveButton,
} from 'styling/buttons';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  fontFamily: 'Gilroy',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type Props = {
  data: VolunteerProfile;
};

let updatedPrivacy: PrivacyPreferences = {};

const Privacy = ({ data }: Props) => {
  const { logout } = useAuth();
  const [preferences] = useState(data ? data.privacy_preferences : {});
  let selectedPreferences = [];

  if (preferences && preferences.learning_nudges)
    selectedPreferences.push('Learning Content Recommendations');
  if (preferences && preferences.newsletter)
    selectedPreferences.push('Newsletter');
  if (preferences && preferences.volunteer_nudges)
    selectedPreferences.push('Mentor Recommendations');
  if (preferences && preferences.show_linkedin_profile)
    selectedPreferences.push('LinkedIn Profile');

  const setValue = (value: any, input: keyof PrivacyPreferences) => {
    updatedPrivacy[input] = value;
    return updatedPrivacy;
  };
  const handleSaveClick = async (privacy: any) => {
    try {
      await apiInstance.patch(`api/organisation/organisations/me/`, {
        privacy_preferences: privacy,
      });
    } catch (e) {
      console.error(e);
    }
    window.location.reload();
  };

  const [open, setOpen] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setPasswordError(null);
  };

  const handleDeleteAccount = async () => {
    try {
      await apiInstance.post(`api/core/deleteaccount/`, {
        password: password,
      });
      Cookie.remove('accessTokenDB');
      Cookie.remove('refreshTokenDB');
      logout('/login?delete=true');
    } catch (e: any) {
      if (e.response.data.password) {
        setPasswordError(e.response.data.password[0]);
      }
      console.error(e);
    }
  };

  return (
    <div className="profile_form">
      <EditInput
        inputField="privacy"
        name={'Email Settings'}
        input={
          selectedPreferences
            ? 'Subscribed to ' + selectedPreferences.join(', ')
            : null
        }
        value={preferences}
        setValue={setValue}
      />
      <div>
        <CustomProfileCancelButton
          variant="outlined"
          sx={{ padding: '8px 10px', borderColor: '#B22222', width: '400px' }}
          onClick={handleOpen}
        >
          Do you want to delete your account?
        </CustomProfileCancelButton>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div style={{ fontFamily: 'Gilroy' }}>
              <h1>
                Are you sure you want to delete all your data and information on
                Digital Boost?
              </h1>
              <p>
                This cannot be undone and you will have to create a new account
                if you wish to use our service again.
              </p>
              <div className="delete-account__container">
                <label>
                  Enter your password to confirm deleting your account
                </label>
                <TextField
                  sx={{ width: '50vw' }}
                  type="password"
                  onChange={(event) => setPassword(event.target.value)}
                  label={<CustomFormLabel>Password</CustomFormLabel>}
                  variant="outlined"
                />
                {passwordError ? (
                  <span className="error">{passwordError}</span>
                ) : null}
                <div>
                  <CustomProfileCancelButton
                    variant="outlined"
                    sx={{
                      padding: '8px 10px',
                      borderColor: '#B22222',
                      width: '300px',
                    }}
                    onClick={handleDeleteAccount}
                  >
                    Yes, I want to delete my account
                  </CustomProfileCancelButton>

                  <CustomProfileSaveButton
                    variant="outlined"
                    sx={{
                      padding: '8px 10px',

                      width: '300px',
                    }}
                    onClick={handleClose}
                  >
                    No, I’ve changed my mind
                  </CustomProfileSaveButton>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      <div className="update-profile-buttons">
        <CustomProfileSaveButton
          variant="contained"
          onClick={() => handleSaveClick(updatedPrivacy)}
        >
          Save
        </CustomProfileSaveButton>
        <CustomProfileCancelButton
          variant="outlined"
          onClick={() => window.location.reload()}
        >
          Cancel
        </CustomProfileCancelButton>
      </div>
    </div>
  );
};

export default Privacy;
