import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import LoginSignup from './pages/LoginSignup';
import ConfirmCall from './pages/ConfirmCall';
import Redirect from 'pages/Redirect/Redirect';
import DeclineCall from './pages/ConfirmCall/DeclineCall';
import CancelCall from './pages/ConfirmCall/CancelCall';
import Error from './pages/Error';
import PartnerLoginPage from './pages/LoginPage/Patner';
import ResetPassword from './components/ResetPassword';
import RequestComplete from './pages/RequestComplete';
import AccessPaused from './pages/AccessPaused';
import NewDashboard from './pages/Dashboard';

import GroupEventDetails from './pages/GroupEventDetails';
import GroupEventRegistered from './pages/GroupEventRegistered';
import GroupEventCall from './pages/GroupEventCall';
import NpsScore from './pages/NpsScore';
import GroupEventScore from './pages/GroupEventScore';
import PremiumPartner from './pages/PremiumPartner';
import PremiumPartnerPreferences from './pages/PremiumPartner/Preferences';
import BoostCall from './pages/BoostCall';
import Verify from './pages/Verify';
import PageNotFound from './pages/PageNotFound';

import ScrollToTop from './components/ScrollToTop';
import { getAccessTokenCookie, getRefreshTokenCookie } from './utils/auth';

import Footer from './components/Footer';
import AppProvider from './AppProvider';
import './App.scss';
import { Helmet } from 'react-helmet';
import Account from 'pages/Account';
import Preferences from 'pages/Preferences';
import ThankYouRegistration from 'pages/ThankYouRegistration';
import AddDetails from 'pages/AddDetails/index';
import QuickMentorSelect from 'pages/MentorSelect';
import NewManageCallDetails from 'pages/CallDetails';
import NewCallConfirmation from 'pages/ConfirmCall/NewCallConfirmation';
import NewVolunteerDetails from 'pages/Rebook/NewVolunteerDetails';
import useRedux from 'hooks/useRedux';
import { createTheme, ThemeProvider } from '@mui/material';
import { selectTheme } from 'features/auth';
import Nav from 'components/Nav';
import MessagingCentre from 'pages/MessagingCentre';
import Resources from 'pages/Resources/Resources';
import SelectAvailability from 'pages/SelectAvailability';
import Library from 'pages/Library';
import PartnerIntegratedSignUp from 'pages/PartnerIntegratedSignUp';
import CookiebotOverlay from 'utils/cookiebot';
import CardManagement from 'pages/ActionPlan/cardManagement';

export const nonAuthProtectedRoutes = [
  '/mentor-select',
  '/select-availability',
  '/error',
  '/login',
  '/group-event-details',
  '/partner-login',
  '/signup',
  '/resetpassword',
  '/verify',
  '/dashboard/actionplan',
  '/to-volunteer-app',
  '/thank-you-registration',
  '/volunteer-details',
  '/partner-integrated-signup',
];

function App() {
  const { useSelector } = useRedux();
  var themeStored = useSelector(selectTheme);
  const [theme, setTheme] = useState(createTheme(themeStored));
  const [hasConsent, setHasConsent] = useState(false);
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const hostnameParts = url.hostname.split('.');
  let subdomain = hostnameParts[0];

  useEffect(() => {
    if (
      window.Cookiebot &&
      window.Cookiebot.consent?.necessary &&
      window.Cookiebot.hasResponse
    ) {
      setHasConsent(true);
      console.log('Consent has been given.');
    }
    const handleConsentReady = () => {
      setHasConsent(true);
    };

    window.addEventListener('CookiebotOnConsentReady', handleConsentReady);

    return () => {
      window.removeEventListener('CookiebotOnConsentReady', handleConsentReady);
    };
  }, []);

  useEffect(() => {
    setTheme(createTheme(themeStored));
  }, [themeStored]);

  useEffect(() => {
    if (process.env.REACT_APP_LOGROCKET_ON === 'true') {
      setupLogRocketReact(LogRocket);
      LogRocket.init('0enqcp/digital-boost');
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Helmet>
          <title>Digital Boost Organisation Portal</title>
          <meta
            name="description"
            content="Digital Boost Organisation Portal "
          />
        </Helmet>
        {!hasConsent && subdomain !== 'localhost' && <CookiebotOverlay />}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <Router>
          <AppProvider>
            <Nav />
            <ScrollToTop>
              <div>
                <Routes>
                  <Route
                    path="/mentor-select"
                    element={<QuickMentorSelect />}
                  />
                  <Route
                    path="/select-availability"
                    element={<SelectAvailability />}
                  />
                  <Route
                    path="/book-session"
                    element={<SelectAvailability />}
                  />
                  <Route path="/add-details" element={<AddDetails />} />
                  <Route
                    path="/dashboard"
                    element={<NewDashboard tab="actions" />}
                  />
                  <Route path=".library" element={<Library />} />
                  <Route path="/redirect" element={<Redirect />} />
                  <Route
                    path="/dashboard/actions"
                    element={<NewDashboard tab="actions" />}
                  />
                  <Route path="/card" element={<CardManagement />} />
                  <Route
                    path="/dashboard/mentoring"
                    element={<NewDashboard tab="mentoring" />}
                  />
                  <Route
                    path="/dashboard/requests"
                    element={<NewDashboard tab="requests" />}
                  />
                  <Route
                    path="/dashboard/events"
                    element={<NewDashboard tab="events" />}
                  />
                  <Route
                    path="/dashboard/recommendations"
                    element={<NewDashboard tab="recommendations" />}
                  />

                  <Route
                    path="/dashboard/actionplan"
                    element={<NewDashboard tab="actionplan" />}
                  />

                  <Route
                    path="/create-plan"
                    element={<NewDashboard tab="newActionPlan" />}
                  />
                  <Route
                    path="/plan"
                    element={<NewDashboard tab="planDetails" />}
                  />
                  <Route
                    path="/dashboard/your-progress"
                    element={
                      <NewDashboard tab="your-progress" subTab="activity" />
                    }
                  />
                  <Route
                    path="/dashboard/your-progress/activity"
                    element={
                      <NewDashboard tab="your-progress" subTab="activity" />
                    }
                  />
                  <Route
                    path="/dashboard/your-progress/feedback"
                    element={
                      <NewDashboard tab="your-progress" subTab="feedback" />
                    }
                  />
                  <Route
                    path="/dashboard/preferences"
                    element={<Preferences tab="learning" />}
                  />
                  <Route
                    path="/dashboard/preferences/learning"
                    element={<Preferences tab="learning" />}
                  />
                  <Route
                    path="/dashboard/preferences/language"
                    element={<Preferences tab="language" />}
                  />
                  <Route
                    path="/dashboard/preferences/mentor"
                    element={<Preferences tab="mentor" />}
                  />
                  <Route
                    path="/dashboard/account"
                    element={<Account tab="profile" />}
                  />
                  <Route
                    path="/dashboard/account/profile"
                    element={<Account tab="profile" />}
                  />
                  <Route
                    path="/dashboard/account/security"
                    element={<Account tab="security" />}
                  />
                  <Route
                    path="/dashboard/account/privacy"
                    element={<Account tab="privacy" />}
                  />
                  <Route path="/boost-call" element={<BoostCall />} />
                  <Route path="/confirm-call" element={<ConfirmCall />} />
                  <Route path="/decline-call" element={<DeclineCall />} />
                  <Route path="/cancel-call" element={<CancelCall />} />
                  <Route path="/error" element={<Error />} />
                  <Route
                    path="/request-complete"
                    element={<RequestComplete />}
                  />
                  <Route path="/access-paused" element={<AccessPaused />} />
                  <Route
                    path="/login"
                    element={<LoginSignup mode={'login'} />}
                  />
                  <Route
                    path="/call-details"
                    element={<NewManageCallDetails />}
                  />
                  <Route
                    path="/call-confirmation"
                    element={<NewCallConfirmation />}
                  />
                  <Route
                    path="/group-event-details"
                    element={<GroupEventDetails />}
                  />
                  <Route
                    path="/group-event-registered"
                    element={<GroupEventRegistered />}
                  />
                  <Route
                    path="/group-event-call"
                    element={<GroupEventCall />}
                  />
                  <Route
                    path="/group-event-nps-score"
                    element={<GroupEventScore />}
                  />
                  <Route path="/partner-login" element={<PartnerLoginPage />} />
                  <Route path="/nps-score" element={<NpsScore />} />
                  <Route
                    path="/signup"
                    element={<LoginSignup mode={'signup'} />}
                  />
                  <Route
                    path="/partner-integrated-signup"
                    element={<PartnerIntegratedSignUp />}
                  />
                  <Route
                    path="/thank-you-registration"
                    element={<ThankYouRegistration />}
                  />
                  <Route path="/resetpassword" element={<ResetPassword />} />
                  <Route path="/premium-partner" element={<PremiumPartner />} />
                  <Route
                    path="/premium-partner-preferences"
                    element={<PremiumPartnerPreferences />}
                  />
                  <Route
                    path="/preferences"
                    element={<Preferences tab="learning" />}
                  />
                  <Route
                    path="/preferences/learning"
                    element={<Preferences tab="learning" />}
                  />
                  <Route
                    path="/preferences/language"
                    element={<Preferences tab="language" />}
                  />
                  <Route
                    path="/preferences/mentor"
                    element={<Preferences tab="mentor" />}
                  />
                  <Route path="/account" element={<Account tab="profile" />} />
                  <Route
                    path="/account/profile"
                    element={<Account tab="profile" />}
                  />
                  <Route
                    path="/account/security"
                    element={<Account tab="security" />}
                  />
                  <Route
                    path="/account/privacy"
                    element={<Account tab="privacy" />}
                  />
                  <Route path="/verify" element={<Verify />} />
                  <Route
                    path="/to-volunteer-app"
                    element={<Navigate to="https://www.digitalboost.org.uk/" />}
                  />
                  <Route
                    path="/"
                    element={
                      !!getAccessTokenCookie() || !!getRefreshTokenCookie() ? (
                        <Navigate replace to="/dashboard/actions" />
                      ) : (
                        <Navigate replace to="/mentor-select" />
                      )
                    }
                  />
                  <Route
                    path="/volunteer-details"
                    element={<NewVolunteerDetails />}
                  />

                  <Route
                    path="/messaging-centre"
                    element={<MessagingCentre />}
                  />
                  <Route path="/dashboard/resources" element={<Resources />} />
                  <Route element={<Navigate replace to="/" />} />
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </div>
            </ScrollToTop>
          </AppProvider>
          {!window.location.href.includes('/cookie-declaration') ? (
            <Footer />
          ) : null}
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
