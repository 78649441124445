import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import {
  CustomFormControlLabel,
  CustomTypography,
} from 'styling/generalStyling';

type Props = {
  setValue: (value: any, input: string) => void;
  value: boolean;
};

export default function EditStageOfBusinessForm({ value, setValue }: Props) {
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="mentoring-radio-buttons-group-label"
        defaultValue={value}
        name="radio-buttons-group"
        sx={{
          '& .Mui-checked': {
            color: '#7FFFD4',
          },
        }}
      >
        <CustomFormControlLabel
          value="pre_start"
          onClick={() => setValue('pre_start', 'stage_of_business')}
          control={<Radio />}
          label={
            <CustomTypography>Pre-start (working on an idea)</CustomTypography>
          }
        />
        <CustomFormControlLabel
          value="starting_out"
          onClick={() => setValue('starting_out', 'stage_of_business')}
          control={<Radio />}
          label={
            <CustomTypography>
              Starting out (launched in the last 18 months)
            </CustomTypography>
          }
        />
        <CustomFormControlLabel
          value="growing"
          onClick={() => setValue('growing', 'stage_of_business')}
          control={<Radio />}
          label={
            <CustomTypography>
              Growing (trading for more than 18 months)
            </CustomTypography>
          }
        />
      </RadioGroup>
    </FormControl>
  );
}
