import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import BookingStatusBar from '../../components/BookingStatusBar';
import ToolTip from '../../components/ToolTip';
import { statusNames } from '../../constants';
import apiInstance from '../../api/config';
import Button from '../../components/Button';
import Cookie from 'js-cookie';
import InputWithLabel from '../../components/InputWithLabel';
import TextAreadWithLabel from '../../components/TextAreaWithLabel';
import ReactTooltip from 'react-tooltip';
// import RefreshFromLinkedIn from '../Preferences/RefreshFromLinkedIn';
import { getUrlParam } from '../../utils/url';
import { replaceUnderscoreWithSpaces } from '../../utils/text';
import ImageUploader from 'react-images-upload';
import { UserProfile, Industry } from '../../types';
import api from '../../api';
import useGetIndustries from '../../apiHooks/useGetIndustries';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import useGetGroupEvents from '../../apiHooks/groupEvents/useGetGroupEvents';
import { useSystemTogglesContext } from '../../context/SystemToggles';
import { capitalCase } from 'change-case';
import SelectOrgType from '../../components/SelectOrgType';

import 'react-datepicker/dist/react-datepicker.css';
import './add-details.scss';
import '../OldSelectAvailability/select-availability.scss';
import { useSelector } from 'react-redux';
import { selectSessionTitle } from 'features/reduxStore/sessionTitle';

const SelectAvailability = () => {
  const nonBookingFlow =
    (!!getUrlParam('non_booking') as boolean) ||
    !Cookie.get('draftBoostCallUUID');
  const navigate = useNavigate();
  const { toggles } = useSystemTogglesContext();
  const autoDetectTimezone = toggles['TIMEZONE_AUTO_DETECT'];

  const groupEventSignup = Cookie.get('groupEventSignup');

  const groupEventInfo = useGetGroupEvents({ code: groupEventSignup });

  const { setOrganisationProfile, setUserLocation, partner } =
    useSelectProfileData();
  const { industries: industryList } = useGetIndustries();

  const [loadingLinkedin, setLoadingLinkedin] = useState<boolean>(false);
  const [skillIds, setSkillIds] = useState<any>([]);
  const [volunteerIds, setVolunteerIds] = useState<any[]>([]);

  const [businessType, setBusinessType] = useState<string>('business');

  const [profilePhoto, setProfilePhoto] = useState<string>('/avatar.png');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [gender, setGender] = useState<string | null>('');
  const [country, setCountry] = useState<string>('GBR');
  const [postCode, setPostCode] = useState<string>('');
  const [bio, setBio] = useState<string>('');
  const [orgName, setOrgName] = useState<string>('');
  const [industry, setIndustry] = useState<number>();
  const [websiteError, setWebsiteError] = useState<string | null>(null);
  const [newsletterOptIn, setNewsletterOptIn] = useState<boolean>(true);

  const [nameError, setNameError] = useState<boolean>(false);
  const [nameLengthError, setNameLengthError] = useState<boolean>(false);
  const [genderError, setGenderError] = useState<boolean>(false);
  const [countryError, setCountryError] = useState<boolean>(false);
  const [postCodeError, setPostCodeError] = useState<boolean>(false);
  const [bioError, setBioError] = useState<boolean>(false);
  const [orgNameError, setOrgNameError] = useState<boolean>(false);
  const [industryError, setIndustryError] = useState<boolean>(false);

  const firstNameEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const genderEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const countryEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const postCodeEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const bioEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const orgNameEl = useRef() as React.MutableRefObject<HTMLInputElement>;

  const [linkedInSaved, setLinkedInSaved] = useState<boolean>(false);

  const browserTimezone = Intl.DateTimeFormat().resolvedOptions()?.timeZone;
  const [timezone, setTimezone] = useState<string>(
    browserTimezone || 'Europe/London'
  );

  const [partnerId, setPartnerId] = useState<null | number | undefined>(
    partner?.id
  );

  const [registeredBusinessNumber, setRegisteredBusinessNumber] =
    useState<string>('?');
  const [website, setWebsite] = useState<string>('');

  const [locationErrorMessage, setLocationErrorMessage] = useState<
    string | boolean
  >(false);
  const [orgErrorMessage, setOrgErrorMessage] = useState<string | boolean>(
    false
  );

  const [countryList, setCountryList] = useState<any[]>([]);
  const [ethnicity, setEthnicity] = useState<number>();
  const [ethnicityList, setEthnicityList] = useState<any[]>([]);
  const [customEthnicity, setCustomEthnicity] = useState<string>('');

  const nextWeek = new Date();
  nextWeek.setDate(new Date().getDate() + 7);
  nextWeek.setMinutes(0);
  nextWeek.setSeconds(0);

  let timeZoneListToBeSet = moment.tz.names();

  timeZoneListToBeSet.push('');

  const [timezoneList] = useState<any>(timeZoneListToBeSet);

  const [isOrgSet, setIsOrgSet] = useState(false);
  const [isLocationSet, setIsLocationSet] = useState(false);

  const handleNext = () => {
    let error = false;
    setNameError(false);
    setNameLengthError(false);
    setGenderError(false);
    setCountryError(false);
    setPostCodeError(false);
    setBioError(false);
    setOrgNameError(false);
    setIndustryError(false);

    setOrgErrorMessage(false);
    setLocationErrorMessage(false);

    if (
      !firstName ||
      !lastName ||
      !gender ||
      !country ||
      !postCode ||
      !bio ||
      !orgName
    ) {
      let elToFocus;

      if (!orgName) {
        setOrgNameError(true);
        elToFocus = orgNameEl;
      }
      if (!bio) {
        setBioError(true);
        elToFocus = bioEl;
      }
      if (!postCode) {
        setPostCodeError(true);
        elToFocus = postCodeEl;
      }
      if (!country) {
        setCountryError(true);
        elToFocus = countryEl;
      }
      if (!gender) {
        setGenderError(true);
        elToFocus = genderEl;
      }
      if (!firstName || !lastName) {
        setNameError(true);
        elToFocus = firstNameEl;
      } else {
        if (firstName.length <= 2 || lastName.length <= 2) {
          setNameLengthError(true);
          elToFocus = firstNameEl;
        }
      }

      elToFocus?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
      return;
    }

    let promiseArray: any[] = [];

    if (isLocationSet) {
      promiseArray.push(updateLocation());
    } else {
      promiseArray.push(createLocation());
    }

    if (isOrgSet) {
      promiseArray.push(updateOrganisation());
    } else {
      promiseArray.push(createOrganisation());
    }

    promiseArray.push(updateDetails());

    Promise.all(promiseArray)
      .then((results) => {
        if (results[1].status === 200 || results[1].status === 201) {
          setIsOrgSet(true);
          setOrganisationProfile(results[1].data); // make sure to update context
        } else {
          setOrgErrorMessage(
            results[1].response.data[Object.keys(results[1].response.data)[0]]
          );
          error = true;
        }

        if (results[0].status === 200 || results[0].status === 201) {
          setIsLocationSet(true);
          setUserLocation(results[0].data); // make sure to update context
        } else {
          setLocationErrorMessage(
            results[0].response.data[Object.keys(results[0].response.data)[0]]
          );
          error = true;
        }

        if (!error) {
          if (!!groupEventSignup) {
            Cookie.remove('groupEventSignup');
            navigate(
              `/group-event-details?code=${groupEventSignup}&register=true`
            );
          } else if (nonBookingFlow) {
            Cookie.set('loggedIn', 'true', { expires: 1 });
            navigate(`/dashboard/actions`);
          } else {
            const redirectURL = Cookie.get('selectAvailabilityUrl');

            if (redirectURL && redirectURL.length > 0) {
              navigate(redirectURL);
              Cookie.remove('selectAvailabilityUrl');
            } else {
              navigate('/select-availability');
            }
          }
        }
      })
      .catch((err: any) => console.log('Promise ALL:', err));
  };

  const getDraftBootsCall = () => {
    const draftBoostCallUUID = Cookie.get('draftBoostCallUUID');

    apiInstance
      .get(`api/event/draftboostcallrequests/${draftBoostCallUUID}/`, {})
      .then((response: any) => {
        setSkillIds(response.data.interests);
        setVolunteerIds(response.data.invited_volunteers);
      })
      .catch((err: any) => console.log(err));
  };

  const code = getUrlParam('code');

  const refreshLinkedIn = useCallback(async () => {
    setLoadingLinkedin(true);

    try {
      let sendObject: { code: any; partner_id?: number; source: string } = {
        code,
        source: 'org-add-details',
      };

      if (partner) {
        sendObject = {
          code,
          partner_id: partner.id,
          source: 'org-add-details',
        };
      }

      const linkedInCallBackResult = await apiInstance.post(
        `api/core/linkedin_callback/`,
        sendObject
      );

      if (linkedInCallBackResult) {
        const liProfile = await apiInstance.get(
          `api/core/linkedinprofiles/me/`
        );

        setLinkedInSaved(true);
        setFirstName(liProfile.data.first_name);
        setLastName(liProfile.data.last_name);
        setProfilePhoto(liProfile.data.profile_picture || profilePhoto);
        setBio(liProfile.data.summary);
      }
      setLoadingLinkedin(false);
    } catch (e) {
      console.error(e);
      setLoadingLinkedin(false);
    }
  }, [code, partner, profilePhoto]);

  const createOrganisation = () => {
    // check if website has http:// or https:// at the start of it. If not then add it in.

    const pattern = /^((http|https|ftp):\/\/)/;

    let newWebsite = website;

    if (!pattern.test(newWebsite) && newWebsite !== '') {
      newWebsite = 'http://' + newWebsite;
    }

    // Allows us to tie a specific signup to a specific GroupEvent code
    const groupEventSignupParam = groupEventInfo
      ? { group_event: groupEventInfo?.groupEventData?.id }
      : {};

    return apiInstance
      .post(`api/organisation/organisations/`, {
        first_name: firstName,
        last_name: lastName,
        name: orgName,
        registration_number: registeredBusinessNumber,
        website: newWebsite,
        partner: partnerId,
        invited_volunteers: volunteerIds,
        interests: skillIds,
        org_type: businessType.toLowerCase(),
        ethnicity: ethnicity,
        custom_ethnicity: customEthnicity,
        industry: industry,
        privacy_preferences: {
          newsletter: newsletterOptIn,
        },
        ...groupEventSignupParam,
      })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        return err;
      });
  };

  const updateDetails = () => {
    return apiInstance
      .patch(`api/core/users/me/`, {
        first_name: firstName,
        last_name: lastName,
        gender: gender,
        summary: bio,
      })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        return err;
      });
  };

  const updateOrganisation = () => {
    const pattern = /^((http|https|ftp):\/\/)/;

    let newWebsite = website;

    if (!pattern.test(newWebsite) && newWebsite !== website) {
      newWebsite = 'http://' + newWebsite;
    }

    return apiInstance
      .patch(`api/organisation/organisations/me/`, {
        first_name: firstName,
        last_name: lastName,
        name: orgName,
        registration_number: registeredBusinessNumber,
        website: newWebsite,
        invited_volunteers: volunteerIds,
        interests: skillIds,
        org_type: businessType.toLowerCase(),
        ethnicity: ethnicity,
        custom_ethnicity: customEthnicity,
        industry: industry,
        privacy_preferences: {
          newsletter: newsletterOptIn,
        },
      })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        return err;
      });
  };

  const updateProfilePhoto = useCallback(
    async (files: File[]) => {
      const formData = new FormData();

      formData.append('profile_picture', files[0]);
      try {
        await apiInstance.patch<UserProfile>(`api/core/users/me/`, formData);
        api.getUserProfile().then((response) => {
          if (response.profile_picture) {
            setProfilePhoto(response.profile_picture || profilePhoto);
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    [profilePhoto]
  );

  const onDrop = (files: File[]) => {
    updateProfilePhoto(files);
  };

  const updateLocation = () => {
    return apiInstance
      .patch(`api/location/locations/me/`, {
        postcode: postCode,
        country: country,
        timezone: timezone,
      })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        return err;
      });
  };
  const session_title = useSelector(selectSessionTitle);
  const createLocation = () => {
    return apiInstance
      .post(`api/location/locations/`, {
        postcode: postCode,
        country: country,
        timezone: timezone,
      })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        return err;
      });
  };

  useEffect(() => {
    (async () => {
      window.scrollTo(0, 0);
      const code = getUrlParam('code');

      if (code) {
        refreshLinkedIn();
      }

      const partnerCookie =
        (getUrlParam('id') as string) || Cookie.get('partner_id');
      if (!!partnerCookie) {
        setPartnerId(parseInt(partnerCookie));
      }

      // if they
      if (
        !groupEventSignup &&
        (Cookie.get('draftBoostCallUUID') === undefined ||
          Cookie.get('refreshOrgTokenDB')) === undefined
      ) {
        navigate('/mentor-select');
      } else {
        if (!!groupEventSignup) {
          const groupEventData = await api.getGroupEvent({
            code: groupEventSignup,
          });
          setVolunteerIds([groupEventData?.volunteer?.id]);
          setSkillIds([groupEventData?.interest?.id]);
        } else {
          getDraftBootsCall();
        }

        api.getCountryList().then((response) => {
          setCountryList(response.data.results);
        });

        api
          .getUserLocation()
          .then((response) => {
            if (response) {
              setIsLocationSet(true);
              setCountry(response.country);
              setPostCode(response.postcode);
              setTimezone(response.timezone);
            }
          })
          .catch((err) => {
            setIsLocationSet(false);
          });

        api.getOrgProfile().then((response) => {
          if (response) {
            setIsOrgSet(true);
            setFirstName(response.first_name);
            setLastName(response.last_name);
            setRegisteredBusinessNumber(response.registration_number);
            setOrgName(response.name);
            setWebsite(response.website);
            setEthnicity(response.ethnicity);
            setCustomEthnicity(response.custom_ethnicity);
            setBusinessType(response.org_type);
            setIndustry(response.industry);
          }
        });

        api.getUserProfile().then((response) => {
          if (response.profile_picture) {
            setProfilePhoto(response.profile_picture || profilePhoto);
          }
          setBio(response.summary);
          setGender(response.gender);
        });

        api.getEthnicityList().then((response) => {
          setEthnicityList(response.data.results);
        });
      }
    })();
    // eslint-disable-next-line
  }, []);

  const ethnicityGroups = ethnicityList.filter((a) => a.parent === null);
  const ethnicityOptgroup = ethnicityGroups.map((item) => {
    const ethnicitySubGroup = ethnicityList.filter((a) => a.parent === item.id);
    const ethnicityOptions = ethnicitySubGroup.map((data) => {
      return (
        <option
          key={`ethnicity-${data.id}`}
          defaultValue={ethnicity}
          value={data.id}
        >
          {data.name}
        </option>
      );
    });

    return (
      <optgroup key={`ethnicity-group-${item.id}`} label={item.name}>
        {ethnicityOptions}
      </optgroup>
    );
  });

  return (
    <div className="add-details select-availability">
      <ReactTooltip place={'top'} arrowColor={'#333333'} />

      <ReactTooltip place="top" id="bio-tooltip" aria-haspopup="true">
        <ul>
          <li>
            a sentence or two about who you are, your background/experience
          </li>
          <li>
            a brief overview of your organisation and its mission (or your
            business idea - if you haven’t started yet)
          </li>
          <li>
            a fun fact! Stuck for inspo? Tell your mentors what song gets you on
            the dance floor, what tv series you binge on repeat or the person
            that inspires you the most
          </li>
        </ul>
      </ReactTooltip>

      {!nonBookingFlow && (
        <BookingStatusBar
          statusNames={statusNames}
          title={'Details'}
          subTitle={`Request your ${session_title}`}
          selectedIndex={3}
        />
      )}
      <div className="border" />
      <div>
        <div className="org-details" data-cy="org-details">
          {locationErrorMessage && (
            <div className="error">{locationErrorMessage}</div>
          )}
          <h2>
            <span className="title-number">1</span>Details: Please can you let
            us know more about you?
          </h2>

          {/* {!linkedInSaved && (
            <div>
              <RefreshFromLinkedIn
                partnerId={partner?.id}
                source="org-add-details"
              />
              <div className="linked-in-description-text">
                Autofill your profile quickly with LinkedIn to improve your
                Mentee experience{' '}
              </div>
            </div>
          )} */}

          {linkedInSaved && (
            <div>
              <h3 style={{ color: '#0274B3' }}>
                Details were autofilled with your Linkedin profile
              </h3>
            </div>
          )}

          <div className="sub-border" />

          <div className="profile-picture-wrapper">
            <div
              className="profile-picture"
              style={{ backgroundImage: `url(${profilePhoto})` }}
            />
            <div
              style={{
                display: 'inline-flex',
                alignContent: 'center',
                flexDirection: 'column',
              }}
            >
              <ImageUploader
                withIcon={false}
                withPreview={false}
                label=""
                singleImage={true}
                buttonText={`${
                  profilePhoto ? 'Change' : 'Upload'
                } Profile Image`}
                onChange={onDrop}
                imgExtension={[
                  '.jpg',
                  '.gif',
                  '.png',
                  '.gif',
                  '.svg',
                  '.peg',
                  '.jpeg',
                ]}
                maxFileSize={2248576}
                fileSizeError="File size is too big, please keep it below 2 MB"
              />
              <div style={{ fontFamily: 'Gilroy', fontSize: '12px' }}>
                Accepted file formats: .jpeg, .jpg, .gif, .png, .svg, .peg
              </div>
            </div>
          </div>

          <div className="names" ref={firstNameEl}>
            <InputWithLabel
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFirstName(e.target.value);
                if (!!lastName && !!nameError) setNameError(false);
                if (lastName.length > 2 && !!nameLengthError)
                  setNameLengthError(false);
              }}
              type={'text'}
              maxLength={30}
              label={'First Name'}
              defaultValue={firstName}
              mandatory
            />
            <InputWithLabel
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLastName(e.target.value);
                if (!!firstName && !!nameError) setNameError(false);
                if (firstName.length > 2 && !!nameLengthError)
                  setNameLengthError(false);
              }}
              type={'text'}
              label={'Last Name'}
              maxLength={30}
              defaultValue={lastName}
              mandatory
            />
          </div>
          {nameError && (
            <span className="add-details__validation-error">
              {'First Name and Last Name are required fields'}
            </span>
          )}
          {nameLengthError && (
            <span className="add-details__validation-error">
              {'First Name and/or Last Name are too short'}
            </span>
          )}

          <div className="edit-gender" ref={genderEl}>
            <fieldset
              className="radio-wrapper"
              data-cy="radio-wrapper-gender"
              aria-required="true"
            >
              <div>
                <label>
                  Gender
                  {
                    <span className="add-details__mandatory-asterisk">
                      {'\u002A '}
                    </span>
                  }
                </label>
                <p>
                  Please state which gender identity you most identify with.
                  Providing your gender data is optional but it will help us
                  better match you to the right mentors and resources.
                </p>
              </div>
              <span
                onClick={() => {
                  setGender('female');
                  setGenderError(false);
                }}
              >
                <input
                  type="radio"
                  value="female"
                  checked={gender === 'female'}
                  required
                />
                <span>Woman</span>
              </span>
              <span
                onClick={() => {
                  setGender('male');
                  setGenderError(false);
                }}
              >
                <input
                  type="radio"
                  value="male"
                  checked={gender === 'male'}
                  required
                />
                <span>Man</span>
              </span>
              <span
                onClick={() => {
                  setGender('non-binary');
                  setGenderError(false);
                }}
              >
                <input
                  type="radio"
                  value="non-binary"
                  checked={gender === 'non-binary'}
                  required
                />
                <span>Non Binary</span>
              </span>
              <span
                onClick={() => {
                  setGender('dont-identify');
                  setGenderError(false);
                }}
              >
                <input
                  type="radio"
                  value="dont-identify'"
                  checked={gender === 'dont-identify'}
                  required
                />
                <span>I don't identify with any of these genders</span>
              </span>
              <span
                onClick={() => {
                  setGender('other');
                  setGenderError(false);
                }}
              >
                <input
                  type="radio"
                  value="other"
                  checked={gender === 'other'}
                  required
                />
                <span>Prefer not to say</span>
              </span>
              <span
                className="question"
                data-tip={
                  'Providing your gender data is optional but it will help us make more suitable recommendations for you. We will report this data anonymously to our partners to improve diversity of our services.'
                }
              >
                <img
                  width={'5px'}
                  height="8px"
                  src="/question.png"
                  alt="question"
                />
              </span>
            </fieldset>
            {genderError && (
              <span className="add-details__validation-error">
                {'Please choose a value'}
              </span>
            )}

            <div className="select-ethnicity">
              <label>Ethnicity</label>

              <select
                value={ethnicity}
                onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                  setEthnicity(parseInt(e.currentTarget.value));
                  if (
                    ethnicityList.filter((a) => a.id === ethnicity).length >
                      0 &&
                    !ethnicityList.filter((a) => a.id === ethnicity)[0]
                      .allow_custom
                  ) {
                    setCustomEthnicity('');
                  }
                }}
              >
                <option
                  key="ethnicity-empty"
                  defaultValue={ethnicity}
                  value=""
                ></option>
                {ethnicityOptgroup}
              </select>
              <span
                style={{ position: 'absolute', marginTop: '19px' }}
                className="question"
                data-tip={
                  'Providing your ethnicity data is optional but it will help us make more suitable recommendations for you. We will report this data anonymously to our partners to improve diversity of our services.'
                }
              >
                <img
                  width={'5px'}
                  height="8px"
                  src="/question.png"
                  alt="question"
                />
              </span>

              {ethnicityList.filter((a) => a.id === ethnicity).length > 0 &&
                ethnicityList.filter((a) => a.id === ethnicity)[0]
                  .allow_custom && (
                  <InputWithLabel
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setCustomEthnicity(e.target.value)
                    }
                    type="text"
                    maxLength={255}
                    placeHolder="Enter the ethnicity you indentify with"
                    defaultValue={customEthnicity}
                  />
                )}
            </div>
          </div>

          <div className="select-country" ref={countryEl}>
            <label>
              Country
              {
                <span className="add-details__mandatory-asterisk">
                  {'\u002A '}
                </span>
              }{' '}
            </label>
            <select
              value={country}
              onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                setCountry(e.currentTarget.value);
                setCountryError(false);
              }}
              required
            >
              {countryList.map((displayCountry, i) => {
                return (
                  <option
                    key={`country-${i}`}
                    defaultValue={country}
                    value={displayCountry.code}
                  >
                    {displayCountry.name}{' '}
                  </option>
                );
              })}
            </select>
          </div>
          {countryError && (
            <span className="add-details__validation-error">
              {'Please specify your country'}
            </span>
          )}
          {autoDetectTimezone ? (
            <div className="add-details__timezone-container">
              <label>Timezone</label>
              <div className="add-details__timezone-inner-container">
                <span>{replaceUnderscoreWithSpaces(timezone)}</span>
                <ToolTip
                  text="Timezone is automatically detected based on your device settings. Times will be displayed according to this timezone."
                  id="add-details-timezone-tooltip"
                  className="add-details__timezone-tooltip"
                />
              </div>
            </div>
          ) : (
            <div className="select-timezone">
              <label>
                Timezone
                {
                  <span className="add-details__mandatory-asterisk">
                    {'\u002A '}
                  </span>
                }{' '}
              </label>
              <select
                value={timezone}
                onChange={(e: React.FormEvent<HTMLSelectElement>) =>
                  setTimezone(e.currentTarget.value)
                }
              >
                {timezoneList.map((displayTimezone: any, i: number) => {
                  return (
                    <option
                      key={`timezone-${i}`}
                      defaultValue={timezone}
                      value={displayTimezone}
                    >
                      {replaceUnderscoreWithSpaces(displayTimezone)}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          <div className="input-container" ref={postCodeEl}>
            <InputWithLabel
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPostCode(e.target.value);
                if (postCodeError) setPostCodeError(false);
              }}
              type={'text'}
              label={'Post Code'}
              defaultValue={postCode}
              mandatory
            />
            <span
              className="question"
              data-tip={
                'You only need to provide the first part of your postcode. Digital Boost receives funding by the UK government. We need this for our reporting purposes.'
              }
            >
              <img
                width={'5px'}
                height="8px"
                src="/question.png"
                alt="question"
              />
            </span>
          </div>

          <div>
            {postCodeError && (
              <span className="add-details__validation-error">
                {'Please provide at least the first part of your postcode'}
              </span>
            )}

            <div className="input-container" ref={bioEl}>
              <TextAreadWithLabel
                defaultValue={bio}
                onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setBio(e.target.value);
                  if (bioError) setBioError(false);
                }}
                label="Type in a bio of yourself"
                cols={60}
                rows={11}
                mandatory
                placeHolder="We're not looking for an autobiography (although you can if you want!) - this is a chance for your mentors to get to know you and decide whether you'll be a good match. Check the tooltip on the side for inspiration"
              />
              <span className="question" data-tip data-for="bio-tooltip">
                <img
                  width={'5px'}
                  height="8px"
                  src="/question.png"
                  alt="question"
                />
              </span>
            </div>
            {bioError && (
              <span className="add-details__validation-error">
                {'A short bio is required'}
              </span>
            )}
            <div className="org-type" ref={orgNameEl}>
              <SelectOrgType
                orgType={businessType}
                setOrgType={setBusinessType}
              />

              {orgErrorMessage && (
                <div className="error">{orgErrorMessage}</div>
              )}

              <InputWithLabel
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setOrgName(e.target.value);
                  if (orgNameError) setOrgNameError(false);
                }}
                type={'text'}
                label={`Name of ${capitalCase(businessType)}`}
                defaultValue={orgName}
                mandatory
              />
              {orgNameError && (
                <span className="add-details__validation-error">
                  {'A value is required for this field'}
                </span>
              )}
            </div>

            <div className="select-sector" data-cy="select-sector">
              <label>Sector {} </label>
              <select
                value={industry}
                onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                  setIndustry(+e.currentTarget.value);
                  if (industryError) setIndustryError(false);
                }}
              >
                <option value="" disabled selected>
                  Select your Sector
                </option>
                {industryList.map((displayIndustry: Industry, i: number) => {
                  return (
                    <option
                      key={`industry-${i}`}
                      defaultValue={''}
                      value={displayIndustry.id}
                    >
                      {displayIndustry.name}{' '}
                    </option>
                  );
                })}
              </select>
              {industryError && (
                <span className="add-details__validation-error">
                  {'Please choose which sector you are in '}
                </span>
              )}
            </div>
            <InputWithLabel
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (
                  e.target.value !== undefined &&
                  !(
                    e.target.value.toLowerCase().startsWith('http') ||
                    e.target.value.toLowerCase().startsWith('www')
                  )
                )
                  setWebsiteError('Please introduce a valid website');
                else {
                  setWebsiteError(null);
                  setWebsite(e.target.value.toLowerCase());
                }
              }}
              type={'text'}
              label={`Website`}
            />
            {websiteError ? (
              <span className="add-details__validation-error">
                {websiteError}
              </span>
            ) : null}

            <div className="newsletter">
              <div className="newsletter-title">
                Would you like to join our newsletter to keep updated and
                connected? (We won't be annoying)
              </div>
              <input
                type="checkbox"
                checked={newsletterOptIn}
                onChange={() => setNewsletterOptIn(!newsletterOptIn)}
              />
              <label>I would like to receive newsletters</label>
            </div>

            <span className="add-details__mandatory-text">
              <span className="add-details__mandatory-asterisk">
                {'\u002A '}
              </span>{' '}
              Mandatory fields
            </span>
          </div>
        </div>
      </div>
      <div
        className={`add-details__button-container ${
          nonBookingFlow ? 'add-details__button-container--single-button' : ''
        }`}
      >
        {!nonBookingFlow && (
          <Button
            text={'Back'}
            type={'secondary'}
            className="add-details__back-button"
            onClickFunction={() => {
              navigate('/select-availability');
            }}
          />
        )}

        <Button
          disabled={loadingLinkedin}
          type={'primary'}
          dataCy={'NextButton'}
          text={'Next'}
          onClickFunction={() => {
            handleNext();
          }}
        />
      </div>
    </div>
  );
};

export default SelectAvailability;
