import React from 'react';
import { useNavigate } from 'react-router-dom';

import './style.scss';
import { formatDate } from 'utils/time';
import { CustomPaper } from 'styling/backgrounds';

type Props = {
  title: string;
  type: string;
  volunteerImage: string;
  volunteerName: string;
  startTime: string;
  timeZone: string;
  code: string;
};

const RecommendationsCard = ({
  title,
  type,
  volunteerImage,
  volunteerName,
  startTime,
  timeZone,
  code,
}: Props) => {
  const navigate = useNavigate();

  const dateFormatted = formatDate(startTime, timeZone);

  const time = {
    time: dateFormatted?.time,
    date: dateFormatted?.date,
  };

  const clickCard = () => navigate(`/group-event-details?code=${code}`);

  return (
    <CustomPaper
      elevation={0}
      className="recomendations-card__container"
      onClick={clickCard}
    >
      <div className="recommendations-card__title-wrapper">
        <div className="recommendations-card__left">
          <div className="recommendations-card__image">
            <img src="/masterclass-icon.png" alt="Event type" />
          </div>

          <div className="recommendations-card__text-info">
            <div className="recommendations-card__title">{title}</div>
            <div className="recommendations-card__type">{type}</div>

            <div className="recommendations-card__user-info">
              <div className="recommendation-card__user">
                <div className="recommendation-card__text">by</div>
                <div className="recommendations-card__user-image-container">
                  <img src={volunteerImage} alt="masterclass user" />
                </div>
                <div className="recommendation-card__text">
                  <strong>{volunteerName}</strong>
                </div>
              </div>

              <div className="recommendation-card__time">
                {`${time?.date?.day} ${time?.date?.month} ${time?.time?.hour}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomPaper>
  );
};

export default RecommendationsCard;
