import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useGetPreferences } from './functions';

import LearningTab from './Components/LearningTab';
import LanguageTab from './Components/LanguageTab';
import MentorTab from './Components/MentorTab';

type Props = {
  tab: string;
};
const PreferenesInfo = ({ tab }: Props) => {
  const data = useGetPreferences();
  if (tab === 'learning' && data) return <LearningTab data={data} />;
  else if (tab === 'language') return <LanguageTab data={data} />;
  else if (tab === 'mentor') return <MentorTab data={data} />;
  else return null;
};

export default PreferenesInfo;
