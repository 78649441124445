import React, { useState } from 'react';
import { VolunteerPreferences } from '../types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import {
  CustomFormControlLabel,
  CustomTypography,
  CustomFormLabel,
} from 'styling/generalStyling';
import apiInstance from '../../../api/config';
import {
  CustomProfileCancelButton,
  CustomProfileSaveButton,
} from 'styling/buttons';

type Props = {
  data: VolunteerPreferences;
};

const MentorTab = ({ data }: Props) => {
  const [value, setValue] = useState<any>(
    data.privacy_preferences && data.privacy_preferences.accept_substitutes
      ? data.privacy_preferences.accept_substitutes
      : false
  );

  const handleSaveClick = async () => {
    try {
      await apiInstance.patch(`api/organisation/organisations/me/`, {
        privacy_preferences: { accept_substitutes: value },
      });
    } catch (e) {
      console.error(e);
    }
    window.location.reload();
  };

  return (
    <div className="mentoring-preferences__container">
      <div className="mentoring-preferences-title">Mentor Substitution</div>
      <FormControl>
        <CustomFormLabel id="gender-radio-buttons-group-label">
          If the volunteer you've selected is not available, we'll substitute
          someone with similar experience so you can get the help you need fast.
        </CustomFormLabel>
        <RadioGroup
          aria-labelledby="gender-radio-buttons-group-label"
          value={value}
          name="radio-buttons-group"
          sx={{
            '& .Mui-checked': {
              color: '#7FFFD4',
            },
          }}
        >
          <CustomFormControlLabel
            label={
              <CustomTypography>
                Yes, I wish to accept alternatives to those I picked
              </CustomTypography>
            }
            onClick={() => setValue(true)}
            control={<Radio />}
            value={true}
          />
          <CustomFormControlLabel
            label={
              <CustomTypography>
                No, I do not wish to accept alternatives to those I picked
              </CustomTypography>
            }
            onClick={() => setValue(false)}
            control={<Radio />}
            value={false}
          />
        </RadioGroup>
      </FormControl>
      <div className="update-preferences-buttons">
        <CustomProfileSaveButton
          variant="contained"
          onClick={() => handleSaveClick()}
        >
          Save
        </CustomProfileSaveButton>
        <CustomProfileCancelButton
          variant="outlined"
          onClick={() => window.location.reload()}
        >
          Cancel
        </CustomProfileCancelButton>
      </div>
    </div>
  );
};

export default MentorTab;
